html {
    background-color: #17171d;
    color: rgb(186, 191, 196);
}

h1 {
    color: rgb(186, 191, 196);
    margin: 0 0 8px 30px;
    padding-top: 24px;
}

h3 {
    color: rgb(186, 191, 196);
    margin-left: 12px;
}

iframe {
    width: calc(100% - 40px);
    height: 321px;
    border: 0;
    margin: 20px 20px 20px;
    border-radius: 15px;
}

iframe.no-style {
    width: calc(100vw - 45px);
    height: calc(100vh - 45px);
    border: 0;
    margin: 20px;
    border-radius: 0;
}

input {
    padding-top: 9px !important;
    padding-bottom: 10px !important;
    text-align: center;
}

p {
    color: rgb(186, 191, 196);
}

.cookie-notice {
    background-color: #17171a;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin: 0 10px 10px;
    border-radius: 5px;
    border: 1px solid #323238;
    max-width: 230px;
    position: fixed;
    bottom: 0;
    right: 0;
    opacity: 95%;
}

.cookie-notice-link {
    color: #90caf9;
    text-decoration: none;
    margin-left: 10px;
}

.decorative-wave {
    aspect-ratio: 15/1;
    width: 100vw;
    min-height: 30vh;
    max-height: 30vh;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    pointer-events: none;
    position: absolute;
    left: 0;
}

.ff-bottom {
    bottom: -3vw;

    -moz-transform: rotate(-1deg);
    -o-transform: rotate(-1deg);
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg);
}

.bottom {
    bottom: -1.5vw;

    -moz-transform: rotate(-1deg);
    -o-transform: rotate(-1deg);
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg);
}

.ff-top {
    top: -3vw;

    -moz-transform: rotate(181deg);
    -o-transform: rotate(181deg);
    -webkit-transform: rotate(181deg);
    transform: rotate(181deg);
}

.top {
    top: -1.5vw;
    margin-left: -15vh;
    -moz-transform: rotate(181deg);
    -o-transform: rotate(181deg);
    -webkit-transform: rotate(181deg);
    transform: rotate(181deg);
}


.app {
    min-height: 100vh;
}

.troubleshooting {
    position: absolute;
    opacity: 1;
    width: calc(100% - 50px);
    max-width: 300px;
    top: 115px;
    left: 25px;
    height: 321px;
    color: rgb(186, 191, 196);
    z-index: -1;
    animation: show-ts 6s;
}

@keyframes show-ts {
    0% {
        opacity: 0
    }
    50% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.all-form-inputs {
    min-width: 232px !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
    margin-bottom: 24px !important;
    margin-top: 12px !important;
}

.all-form-inputs-without-margin {
    min-width: 232px !important;
    margin: 18px 0 0 0!important;
}

.MuiOutlinedInput-input {
    color: white !important;
}

.MuiSelect-select {
    padding-left: 10px !important;
}

.freq {
    width: 60px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-bottom: 12px !important;
    margin-top: -3px !important;
}

#full-screen {
    width: calc(100% - 40px);
    margin: 15px 20px;
    color: rgb(186, 191, 196)
}

.big-container {
    border-bottom: 1px solid #323238;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.s-container {
    border: 1px solid rgba(118, 170, 214, 0.5);
    width: fit-content;
    display: inline-block;
    margin: 12px;
    padding: 15px 15px 3px;
    border-radius: 15px;
}

.f-container {
    border-radius: 15px;
    width: fit-content;
    display: inline-block;
    vertical-align: top;
    margin: 0 25px 0 25px;
}

.share-container {
    border-radius: 15px;
    float: right;
    margin: 0 25px 0 25px;
    width: 310px;
}

.footer-p {
    color: #babfc4;
    font-size: 15px;
}

.a-footer {
    text-decoration: none;
}

.footer {
    width: 100%;
    min-height: 230px;
    background-color: #040409;
    color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: 50px;
}

.music-text {
    margin-right: 20px;
    margin-left: 20px;
    position: relative;
    top: 6px;
}

.react-share__ShareButton {
    margin: 6px;
}

.right-when-large {
    float: right;
}

.box-shadowy {
    border: 1px solid #232329;
    padding: 10px 20px 10px 20px;
    border-radius: 20px;
    min-width: 169px;
}

.time-slider {
    vertical-align: middle;
}

@media (max-width: 1600px) {
    .right-when-large {
        float: unset;
    }
}

@media (min-width: 1110px) {
    .text-margin {
        height: 0;
        margin: 0;
    }
}

@media (min-height: 650px) {
    .sticky-canvas {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 11;
        background-color: #17171d;
        box-shadow: 0 2px 5px -2px #040409;
        margin-bottom: 12px;
    }
    .troubleshooting {
        top: 40px;
    }
}

@media (max-width: 640px) {
    .bottom-button {
        width: 100%;
    }
    label {
        width: 100%;
    }
    .all-form-inputs {
        width: calc(100vw - 175px);
    }
    .all-form-inputs-without-margin {
        width: calc(100vw - 175px);
    }
    .freq {
        width: 100% !important;
        margin-left: 0 !important;
        margin-top: -15px !important;
    }
    h1 {
        text-align: center;
    }
    .share-container {
        width: 100%;
        margin-right: 0;
    }
    .box-shadowy {
        width: calc(100vw - 140px);
    }

    .float-container{
        width: 100%;
    }
}

@media (min-width: 641px) {
    .record {
        max-width: fit-content !important;
    }
}

@media (pointer: none),
(pointer: coarse) {
    .freq {
        display: none !important;
    }
}